import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import CVSection from "./CVSection";
import cvStyles from "./CVStyles.module.css";
import CVSubsection from "./CVSubsection";
import PDFButton from "./PDFButton";
import SpokenLanguages from "./SpokenLanguages";
import WorkExperienceSection from "./WorkExperienceSection";

export default function CVPage() {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Helmet>
        <title>{t("MaxHaertwig")} - {t("CV")}</title>
      </Helmet>
      <PDFButton url={process.env.PUBLIC_URL + "/Max-Haertwig-Resume.pdf"} />
      <article>
        <CVSection title={t("Education")}>
          <WorkExperienceSection
            position={t("ComputerScienceDiplom")}
            company={t("DresdenUniversityOfTechnology")}
            companyLink={t("TUDresdenLink")}
            location={t("DresdenGermany")}
            timespan={[10, 2016, 1, 2022]}
          >
            {t("UniversityDetail1")}
            <Trans i18nKey="UniversityDetail2">
              <a href="https://nbn-resolving.org/urn:nbn:de:bsz:14-qucosa2-782524">_</a>
            </Trans>
            <Trans i18nKey="UniversityDetail3">
              <a href="http://dx.doi.org/10.5381/jot.2022.21.3.a2">_</a> <a href="https://davidediruscio.github.io/ECMFA2022">_</a>
            </Trans>
          </WorkExperienceSection>
        </CVSection>
        <CVSection title={t("ProfessionalExperience")}>
          <WorkExperienceSection
            position={t("SoftwareEngineer")}
            company="Google"
            companyLink={t("GoogleLink")}
            location={t("ZurichSwitzerland")}
            timespan={[2, 2022]}
          >
            {t("GoogleDetail")}
          </WorkExperienceSection>
          <CVSubsection title={t("Internships")}>
            <WorkExperienceSection
              position={t("iCloudSoftwareEngineeringIntern")}
              company="Apple Inc."
              companyLink={t("AppleLink")}
              location="Cupertino,&nbsp;CA,&nbsp;USA"
              remote
              timespan={[6, 2021, 9, 2021]}
            >
              {t("Apple2021Detail")}
            </WorkExperienceSection>
            <WorkExperienceSection
              position={t("SoftwareEngineeringIntern")}
              company="Google"
              companyLink={t("GoogleLink")}
              location={t("MunichGermany")}
              remote
              timespan={[3, 2021, 6, 2021]}
            >
              {t("GoogleInternshipDetail")}
            </WorkExperienceSection>
            <WorkExperienceSection
              position={t("iCloudSoftwareEngineeringIntern")}
              company="Apple Inc."
              companyLink={t("AppleLink")}
              location="Cupertino,&nbsp;CA,&nbsp;USA"
              remote
              timespan={[3, 2020, 9, 2020]}
            >
              {[1, 2, 3, 4].map(n => t("Apple2020Detail" + n))}
            </WorkExperienceSection>
            <WorkExperienceSection
              position={t("AISoftwareEngineeringIntern")}
              company="MBition (Mercedes-Benz Innovation Lab)"
              companyLink={t("MBitionLink")}
              location={t("BerlinGermany")}
              timespan={[9, 2019, 2, 2020]}
            >
              {[1, 2].map(n => t("MBitionDetail" + n))}
            </WorkExperienceSection>
          </CVSubsection>
          <CVSubsection title={t("StudentJobs")}>
            <WorkExperienceSection
              position={t("JuniorSoftwareDeveloper")}
              company="DevBoost GmbH"
              companyLink={t("DevBoostLink")}
              location={t("DresdenGermany")}
              timespan={[11, 2017, 8, 2019]}
            >
              {[1, 2, 3].map(n => t("DevBoostDetail" + n))}
            </WorkExperienceSection>
            <WorkExperienceSection
              position={t("iOSSoftwareDeveloper")}
              company="Deutscher Telemarkt GmbH"
              companyLink="https://www.dtele.de"
              location={t("DresdenGermany")}
              timespan={[10, 2016, 1, 2019]}
            >
              <Trans i18nKey="TelemarktDetail1">
                <a href={t("AddigoWebsiteLink")}>_</a>
              </Trans>
              {t("TelemarktDetail2")}
            </WorkExperienceSection>
          </CVSubsection>
          <WorkExperienceSection
            position={t("IndependentSoftwareDeveloper")}
            timespan={[11, 2011, 1, 2022]}
          >
            <Trans i18nKey="IndieDevDetail1">
              <a href={t("LearnboxWebsiteLink")}>_</a>
            </Trans>
            {t("IndieDevDetail2")}
            <Trans i18nKey="IndieDevDetail3">
              <a href="https://github.com/maxhaertwig">_</a>
            </Trans>
          </WorkExperienceSection>
        </CVSection>
        <CVSection title={t("LanguagesAndSkills")}>
          <div className={cvStyles.subsection}>
            <SpokenLanguages />
          </div>
          <div className={cvStyles.subsection}>
            <strong>{t("ProgrammingLanguages")}:</strong> Swift, Python, TypeScript/JavaScript, Java, Objective-C, Go, SQL
          </div>
          <div className={cvStyles.subsection}>
            <strong>{t("Technologies")}:</strong> iOS SDK (UIKit, SwiftUI, Core Data, CloudKit), Node.js, React, Flask
          </div>
        </CVSection>
        <CVSection title={t("LeadershipAndAwards")}>
          <div className={cvStyles.subsection}>
            <p className={cvStyles.metadata}>Jun. 2019</p>
            <div className={cvStyles.subheading}>
              <span><strong><a href="https://developer.apple.com/wwdc">WWDC</a> 2019 {t("ScholarshipWinner")}</strong></span>
              <span> </span>
            </div>
            <ul className={cvStyles.ul}>
              <li className={cvStyles.li}>{t("WWDCDetail")}</li>
            </ul>
          </div>
          <div className={cvStyles.subsection}>
            <p className={cvStyles.metadata}>Apr. 2019</p>
            <div className={cvStyles.subheading}>
              <span>
                <Trans i18nKey="MicrosoftHackathonPrize">
                  <strong>_</strong> _ <strong>_</strong>
                </Trans>
              </span>
            </div>
          </div>
          <div className={cvStyles.subsection}>
            <p className={cvStyles.metadata}>{t("MarAbbr")} 2018</p>
            <div className={cvStyles.subheading}>
              <span>
                <Trans i18nKey="HuaweiScholarship">
                  <strong>_</strong> <a href="https://huawei.eu/what-we-do/seeds-future">_</a>
                </Trans>
              </span>
            </div>
            <ul className={cvStyles.ul}>
              {[1, 2].map(n => <li key={n} className={cvStyles.li}>{t("HuaweiDetail" + n)}</li>)}
            </ul>
          </div>
          <div className={cvStyles.subsection}>
            <p className={cvStyles.metadata}>{t("JunAbbr")} 2017 - {t("MarAbbr")} 2022</p>
            <div className={cvStyles.subheading}>
              <span>
                <Trans i18nKey="GNSFScholarship">
                  <strong>_</strong> _ <a href={t("GNSFLink")}>_</a>
                </Trans>
              </span>
            </div>
            <ul className={cvStyles.ul}>
              {[1, 2].map(n => <li key={n} className={cvStyles.li}>{t("GNSFDetail" + n)}</li>)}
            </ul>
          </div>
          <div className={cvStyles.subsection}>
            <p className={cvStyles.metadata}>{t("JunAbbr")} 2016</p>
            <div className={cvStyles.subheading}>
              <span>
                <Trans i18nKey="GraduationPrizeMathematics">
                  <strong>_</strong>
                </Trans>
              </span>
              <span> </span>
            </div>
          </div>
        </CVSection>
      </article>
    </Fragment >
  );
}
