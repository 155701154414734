import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import CVPage from "./cv/CVPage";
import HomePage from "./home/HomePage";
import PrivacyPolicyPage from "./privacy-policy/PrivacyPolicyPage";
import Header from "./shared/Header";
import NotFoundPage from "./shared/NotFoundPage";
import ReadableContent from "./shared/ReadableContent";
import ScrollToTop from "./shared/ScrollToTop";
import SetLanguageComponent from "./shared/SetLanguageComponent";

export default function App() {
  const privacyPolicies = [
    ["/privacy-policy/learnbox", "Learnbox"],
    ["/privacy-policy/apc1404", "ProdCalc1404PP"],
    ["/privacy-policy/apc1800", "ProdCalc1800PP"]
  ];
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <SetLanguageComponent />
      <ScrollToTop />
      <Header />
      <ReadableContent>
        <Switch>
          <Redirect exact from="/en" to="/" />
          <Redirect exact from="/en/cv" to="/cv" />

          <Route exact path="/(de)?">
            <HomePage />
          </Route>
          <Route exact path="(/de)?/cv">
            <CVPage />
          </Route>
          {privacyPolicies.map(tuple =>
            <Route key={tuple[0]} exact path={"(/de)?" + tuple[0]}>
              <PrivacyPolicyPage app={tuple[1]} />
            </Route>
          )}
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </ReadableContent>
    </BrowserRouter>
  );
}
