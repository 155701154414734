import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import SectionHeading from "./SectionHeading";

export default function NotFoundPage() {
  const {t} = useTranslation();
  return (
    <Fragment>
      <Helmet>
        <title>{t("MaxHaertwig")} - {t("PageNotFound")}</title>
      </Helmet>
      <article>
        <SectionHeading text={t("PageNotFound")} />
        <p>{t("PageNotFoundContent")}</p>
      </article>
    </Fragment>
  );
}