import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./SocialIcons.module.css";
import SocialIcon from "./SocialIcon";
import emailIcon from "../res/social-icons/email-icon.svg";
import emailIconDark from "../res/social-icons/email-icon-dark.svg";
import twitterIcon from "../res/social-icons/twitter-icon.svg";
import gitHubIcon from "../res/social-icons/github-icon.svg";
import gitHubIconDark from "../res/social-icons/github-icon-dark.svg";
import linkedInIcon from "../res/social-icons/linkedin-icon.svg";
import linkedInIconDark from "../res/social-icons/linkedin-icon-dark.svg";

export default function SocialIcons() {
  const {t} = useTranslation();
  return (
    <div className={styles.socialIcons}>
      <SocialIcon
        destination={t("Email")}
        url="mailto:contact@maxhaertwig.com"
        icon={emailIcon}
        darkModeIcon={emailIconDark}
      />
      <SocialIcon
        destination={`Twitter ${t("Profile")}`}
        url="https://twitter.com/MaxHaertwig"
        icon={twitterIcon}
        round
      />
      <SocialIcon
        destination={`GitHub ${t("Profile")}`}
        url="https://github.com/MaxHaertwig"
        icon={gitHubIcon}
        darkModeIcon={gitHubIconDark}
        round
      />
      <SocialIcon
        destination={`LinkedIn ${t("Profile")}`}
        url="https://www.linkedin.com/in/max-haertwig/"
        icon={linkedInIcon}
        darkModeIcon={linkedInIconDark}
      />
    </div>
  );
}
