import PropTypes from "prop-types";
import React, { Fragment } from "react";
import styles from "./CVSubsection.module.css";

export default function CVSubsection({title, children}) {
  return (
    <Fragment>
      <h3>{title}</h3>
      <div className={styles.inset}>{children}</div>
    </Fragment>
  );
}

CVSubsection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};
