import PropTypes from "prop-types";
import React, { Fragment } from "react";

export default function AdaptiveImage({image, darkModeImage, alt, className}) {
  const imageElement = <img className={className || ""} src={image} alt={alt} draggable={false} />;
  return (
    <Fragment>
      {darkModeImage &&
      <picture>
        <source srcSet={darkModeImage} media="(prefers-color-scheme: dark)" />
        {imageElement}
      </picture>
      }
      {!darkModeImage &&
      imageElement
      }
    </Fragment>
  );
}

AdaptiveImage.propTypes = {
  image: PropTypes.any.isRequired,
  darkModeImage: PropTypes.any,
  alt: PropTypes.string,
  className: PropTypes.string
};
