import React from "react";
import { useTranslation } from "react-i18next";
import spmIcon from "../../res/spm-icon.png";
import saveOurPlanetMap from "../../res/save-our-planet-icon.png";
import SectionHeading from "../../shared/SectionHeading";
import OpenSourceProjectComponent from "../OpenSourceProjectComponent";
import styles from "./ProjectsSection.module.css";

export default function ProjectsSection() {
  const {t} = useTranslation();
  return (
    <section className={styles.projectsSection}>
      <SectionHeading text={t("OpenSourceProjects")} />
      <div>
        <OpenSourceProjectComponent
          icon={spmIcon}
          additionalIconClass={styles.spmIcon}
          name="SwiftyBibtex"
          gitHubRepoName="SwiftyBibtex"
        >
          {t("SwiftyBibtexDescription")}
        </OpenSourceProjectComponent>
        <OpenSourceProjectComponent
          icon={spmIcon}
          additionalIconClass={styles.spmIcon}
          name="SwiftyHolidays"
          gitHubRepoName="SwiftyHolidays"
        >
          {t("SwiftyHolidaysDescription")}
        </OpenSourceProjectComponent>
        <OpenSourceProjectComponent
          icon={saveOurPlanetMap}
          additionalIconClass={styles.savingOurPlanetIcon}
          name={t("WWDCProject")}
          gitHubRepoName="WWDC-2019-Saving-our-Planet"
          additionalButtons={[
            [t("DownloadPlayground"), process.env.PUBLIC_URL + "/Saving-our-Planet.playgroundbook.zip"]
          ]}
        >
          {t("WWDCProjectDescription")}
        </OpenSourceProjectComponent>
      </div>
    </section>
  );
}
