import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./HeroSection.module.css";
import heroImage from "../../res/hero.jpeg";
import SocialIcons from "../SocialIcons";

export default function HeroSection() {
  const { t } = useTranslation();
  return (
    <div className={styles.hero}>
      <div className={styles.leftSide}>
        <div className={styles.text}>
          <div className={styles.greeting}>{t("Greeting")}</div>
          <h1 className={styles.name}>{t("MaxHaertwig")}</h1>
          <div className={styles.subtitle}>{t("Subtitle")}</div>
          <p className={styles.aboutMe}>
            <Trans i18nKey="AboutMe">
              <a href={t("LearnboxAppStoreLink")}>_</a>
            </Trans>
          </p>
          <SocialIcons />
        </div>
      </div>
      <img src={heroImage} alt={t("MaxHaertwig")} className={styles.heroImage} draggable={false} />
    </div>
  );
}
