import { useEffect } from "react";
import { useHistory} from "react-router-dom";

export default function ScrollToTop() {
  const history = useHistory();
  useEffect(() => {
    return history.listen((location, action) => {
      if (action === "PUSH") {
        window.scrollTo(0, 0);
      }
    });
  }, [history]);
  return null;
}
