import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./Header.module.css";
import HeaderLink from "./HeaderLink";
import logo from "../res/logo.svg";
import flagGermany from "../res/flags/flag-germany.svg";
import flagUSA from "../res/flags/flag-usa.svg";

export default function Header() {
  const {t} = useTranslation();
  const path = useLocation().pathname;
  const changeLanguageLink = path === "/" ? "/de" : path === "/de" ? "/" : path.startsWith("/de") ? path.substring(3) : "/de" + path;
  return (
    <header className={styles.header}>
      <NavLink to={t("Lang") === "en" ? "/" : "/de"}>
        <img src={logo} alt={t("Logo")} className={styles.logo} />
      </NavLink>
      <nav className={styles.nav}>
        <HeaderLink to="/" text={t("Home")} />
        <HeaderLink to="/cv" text={t("CV")} />
        <NavLink exact to={changeLanguageLink} className={styles.flagContainer} style={{height: t("Lang") === "de" ? 14 : 18}}>
          {t("Lang") === "de" &&
          <img className={styles.languageFlag} style={{width: 32, height: 17}} src={flagUSA} alt={t("SwitchToEnglish")} />
          }
          {t("Lang") === "en" &&
          <img className={styles.languageFlag} style={{width: 30, height: 18}} src={flagGermany} alt={t("SwitchToGerman")} />
          }
        </NavLink>
      </nav>
    </header>
  );
}
