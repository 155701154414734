import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styles from "./HeaderLink.module.css";

export default function HeaderLink({to, text}) {
  const {t} = useTranslation();
  let resolvedTo = to;
  if (t("Lang") === "de") {
    if (resolvedTo === "/") {
      resolvedTo = "/de";
    } else if (!resolvedTo.startsWith("/de")) {
      resolvedTo = "/de" + resolvedTo;
    }
  }
  return (
    <NavLink exact to={resolvedTo} className={styles.navLink} activeClassName={styles.activeLink}>
      {text}
    </NavLink>
  );
}

HeaderLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};
