import PropTypes from "prop-types";
import React from "react";
import AdaptiveImage from "../shared/AdaptiveImage";
import styles from "./SocialIcon.module.css";

export default function SocialIcon({destination, url, icon, darkModeIcon, round}) {
  return (
    <a className={styles.socialLink} href={url}>
      <AdaptiveImage image={icon} darkModeImage={darkModeIcon} alt={destination} className={round ? styles.socialIconRound : styles.socialIconSquare} />
    </a>
  );
}

SocialIcon.propTypes = {
  destination: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  darkModeIcon: PropTypes.any,
  round: PropTypes.bool
};
