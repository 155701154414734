import PropTypes from "prop-types";
import React from "react";
import styles from "./ReadableContent.module.css";

export default function ReadableContent({children}) {
  return (
    <div className={styles.readableContent}>
      {children}
    </div>
  );
}

ReadableContent.propTypes = {
  children: PropTypes.node
};
