import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import appStoreBannerDe from "../res/app-store/app-store-de.svg";
import appStoreBannerEn from "../res/app-store/app-store-en.svg";
import styles from "./AppComponent.module.css";
import ProjectComponent from "./ProjectComponent";

export default function AppComponent({icon, name, learnMoreLink, appStoreLink, children}) {
  const {t} = useTranslation();
  return (
    <ProjectComponent
      icon={icon}
      name={name}
      buttons={[
        [t("LearnMore"), learnMoreLink],
        <a key="banner" className={styles.appStoreBanner} href={appStoreLink}>
          <img src={t("Lang") === "de" ? appStoreBannerDe : appStoreBannerEn} alt={t("DownloadAppStore")} />
        </a>
      ]}
    >
      {children}
    </ProjectComponent>
  );
}

AppComponent.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  learnMoreLink: PropTypes.string.isRequired,
  appStoreLink: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
