import PropTypes from "prop-types";
import React from "react";
import styles from "./Button.module.css";

export default function Button({text, url}) {
  return (
    <a className={styles.button} href={url}>
      <div>
        <span className={styles.buttonText}>{text}</span>
      </div>
    </a>
  );
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};
