import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProjectComponent from "./ProjectComponent";

export default function OpenSourceProjectComponent({icon, additionalIconClass, name, gitHubRepoName, additionalButtons, children}) {
  const {t} = useTranslation();
  const [stars, setStars] = useState(null);

  useEffect(() => {
    fetch("https://api.github.com/repos/MaxHaertwig/" + gitHubRepoName, {headers: {"Accept": "application/vnd.github.v3+json"}})
      .then(res => res.json())
      .then(res => setStars(parseInt(res["stargazers_count"])));
  }, [gitHubRepoName]);

  return (
    <ProjectComponent
      icon={icon}
      additionalIconClass={additionalIconClass}
      name={typeof stars != "number" || isNaN(stars) ? name : [name, "☆ " + stars]}
      buttons={[[t("ViewOnGitHub"), "https://github.com/MaxHaertwig/" + gitHubRepoName]].concat(additionalButtons || [])}
    >
      {children}
    </ProjectComponent>
  );
}

OpenSourceProjectComponent.propTypes = {
  icon: PropTypes.string.isRequired,
  additionalIconClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  gitHubRepoName: PropTypes.string.isRequired,
  additionalButtons: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.element
  ])),
  children: PropTypes.node.isRequired
};
