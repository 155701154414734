import React  from "react";
import styles from "./SpokenLanguages.module.css";
import { useTranslation } from "react-i18next";

export default function SpokenLanguages() {
  const {t} = useTranslation();
  if (t("Lang") === "de") {
    return (
      <p className={styles.textIndent}>
        <strong>{t("SpokenLanguages")}:</strong>{" "}
        {t("German")}&nbsp;<span className={styles.detail}>{t("GermanDescription")}</span>,{" "}
        {t("English")}&nbsp;<span className={styles.detail}>{t("EnglishDescription")}</span>,{" "}
        {t("French")}&nbsp;<span className={styles.detail}>{t("FrenchDescription")}</span>,{" "}
        {t("Chinese")}&nbsp;<span className={styles.detail}>{t("ChineseDescription")}</span>
      </p>
    );
  } else {
    return (
      <p className={styles.textIndent}>
        <strong>{t("SpokenLanguages")}:</strong>{" "}
        {t("English")}, {t("German")},{" "}
        {t("French")}&nbsp;<span className={styles.detail}>{t("FrenchDescription")}</span>,{" "}
        {t("Chinese")}&nbsp;<span className={styles.detail}>{t("ChineseDescription")}</span>
      </p>
    );
  }
}
