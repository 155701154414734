import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import AppsSection from "./sections/AppsSection";
import HeroSection from "./sections/HeroSection";
import ProjectsSection from "./sections/ProjectsSection";

export default function HomePage() {
  const {t} = useTranslation();
  return (
    <Fragment>
      <Helmet>
        <title>{t("MaxHaertwig")} - {t("Home")}</title>
        <meta name="description" content={t("AboutMe")} />
      </Helmet>
      <HeroSection />
      <AppsSection />
      <ProjectsSection />
    </Fragment>
  );
}
