import PropTypes from "prop-types";
import React from "react";
import styles from "./SectionHeading.module.css";

export default function SectionHeading({id, text}) {
  return (
    <h2 id={id || null} className={styles.heading}>
      {text.split(" ").map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(" ")}
    </h2>
  );
}

SectionHeading.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string.isRequired
};
