import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import CVSpacer from "./CVSpacer";
import cvStyles from "./CVStyles.module.css";
import styles from "./WorkExperienceSection.module.css";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export default function WorkExperienceSection({position, company, companyLink, location, remote, timespan, upcoming, children}) {
  const {t} = useTranslation();

  const start = `${t(months[timespan[0] - 1] + "Abbr")} ${timespan[1]}`;
  let timespanString;
  if (upcoming && timespan.length <= 2) {
    timespanString = t("From") + " " + start;
  } else {
    timespanString = start + " - " + (timespan.length > 2 ? `${t(months[timespan[2] - 1] + "Abbr")} ${timespan[3]}` : t("Present"));
  }

  const companyString = company ? [<CVSpacer key={0} />, (companyLink ? <a key={1} href={companyLink} className={styles.link}><strong>{company}</strong></a> : <strong key={1}>{company}</strong>)] : "";
  const locationString = location ? [<CVSpacer key={0} />, <span key={1}>{location + (remote ? " (Remote)" : "")}</span>] : "";
  const listItems = children ? (Array.isArray(children) ? children.flat() : [children]) : null;

  return (
    <div className={cvStyles.subsection}>
      <p className={cvStyles.metadata}>{timespanString}{locationString}</p>
      <div className={cvStyles.subheading + (upcoming ? ` ${styles.upcoming}` : "")}>
        <span><strong>{position}</strong>{companyString}</span>
      </div>
      {listItems &&
      <ul className={cvStyles.ul}>
        {listItems.map((child, i) => <li key={i} className={cvStyles.li}>{child}</li>)}
      </ul>
      }
    </div>
  );
}

WorkExperienceSection.propTypes = {
  position: PropTypes.string.isRequired,
  company: PropTypes.string,
  companyLink: PropTypes.string,
  location: PropTypes.string,
  remote: PropTypes.bool,
  timespan: PropTypes.arrayOf(PropTypes.number).isRequired,
  upcoming: PropTypes.bool,
  children: PropTypes.node
};
