import React from "react";
import { Trans, useTranslation } from "react-i18next";
import apc1800Icon from "../../res/app-icons/apc-1800-icon.png";
import learnboxIcon from "../../res/app-icons/learnbox-icon.png";
import SectionHeading from "../../shared/SectionHeading";
import AppComponent from "../AppComponent";

export default function AppsSection() {
  const {t} = useTranslation();
  return (
    <section>
      <SectionHeading text={t("Apps")} />
      <div>
        <AppComponent
          name={t("Learnbox")}
          icon={learnboxIcon}
          appStoreLink={t("LearnboxAppStoreLink")}
          learnMoreLink={t("LearnboxWebsiteLink")}
        >
          {t("LearnboxDescription")}
        </AppComponent>
        <AppComponent
          name={t("ProdCalc1800")}
          icon={apc1800Icon}
          appStoreLink={t("ProdCalc1800AppStoreLink")}
          learnMoreLink={t("ProdCalc1800AppStoreLink")}
        >
          <Trans i18nKey="ProdCalc1800Description">
            _ <a href={t("ProdCalc1404AppStoreLink")}>x</a> _ <a href={t("ProdCalc2070AppStoreLink")}>_</a>
          </Trans>
        </AppComponent>
      </div>
    </section>
  );
}
