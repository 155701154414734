import PropTypes from "prop-types";
import React from "react";
import Button from "./Button";
import styles from "./ProjectComponent.module.css";

export default function ProjectComponent({icon, additionalIconClass, name, buttons, children}) {
  const subtitle = typeof name != "string" ? <span className={styles.subtitle}>{name[1]}</span> : null;
  return (
    <div className={styles.projectSection}>
      <div className={styles.imageContainer}>
        <img className={styles.projectIcon + " " + additionalIconClass} src={icon} alt={name + " Icon"} />
        <h3 className={styles.projectNameMobile}>{name}{subtitle}</h3>
      </div>
      <div className={styles.textAndButtons}>
        <h3 className={styles.projectName}>{typeof name === "string" ? name : name[0]}{subtitle}</h3>
        <p className={styles.projectDescription}>{children}</p>
        {buttons &&
        <div className={styles.projectButtons}>
          {buttons.map((x, i) => Array.isArray(x) ? <Button key={i} text={x[0]} url={x[1]} /> : x)}
        </div>
        }
      </div>
    </div>
  );
}

ProjectComponent.propTypes = {
  icon: PropTypes.string.isRequired,
  additionalIconClass: PropTypes.string,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  buttons: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.element
  ])),
  children: PropTypes.node.isRequired
};
