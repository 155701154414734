import PropTypes from "prop-types";
import React from "react";
import SectionHeading from "../shared/SectionHeading";
import styles from "./CVSection.module.css";

export default function CVSection({title, children}) {
  return (
    <section className={styles.cvSection}>
      <SectionHeading text={title} />
      {children}
    </section>
  );
}

CVSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};
