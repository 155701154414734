import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./PDFButton.module.css";

export default function PDFButton({url}) {
  const {t} = useTranslation();
  return <a className={styles.pdfButton} href={url}>{t("PDF")}</a>;
}

PDFButton.propTypes = {
  url: PropTypes.string.isRequired
};
