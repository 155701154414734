import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function SetLanguageComponent() {
  const {t, i18n} = useTranslation();
  const path = useLocation().pathname;
  if (path) {
    const pathIsGerman = path === "/de" || path.startsWith("/de/");
    const languageIsGerman = t("Lang") === "de";
    if (pathIsGerman !== languageIsGerman) {
      i18n.changeLanguage(pathIsGerman ? "de" : "en");
    }
  }
  return (
    <Helmet>
      <html lang={t("Lang")} />
      <title>{t("MaxHaertwig")}</title>
    </Helmet>
  );
}
